
import {Component, Vue} from 'vue-property-decorator';
import VMaskPhone from '@/UI/VMaskPhone.vue';
import AppStore from '@/components/AppStore';
import UserManager from '@/models/UserManager';
import TelegramIcon from '@/components/icons/TelegramIcon.vue';
import ManagerWrapper from '@/components/ManagerWrapper.vue';

@Component({
  components: {ManagerWrapper, TelegramIcon, VMaskPhone}
})
export default class ManagerBlock extends Vue {

  manager = new UserManager();
  assistant = new UserManager();

  loading = false;

  showReportCard = false;

  created() {
    this.manager = AppStore.manager;
    this.assistant = AppStore.assistant;
    this.showReportCard = window.location.hostname === 'lk.transpropusk.ru';
  }

}

